/* Assicurati che l'immagine abbia dimensioni costanti e si posizioni a destra del testo */
.maintenance-image {
    width: 320px; /* Larghezza costante per l'immagine */
    height: auto; /* Mantiene il rapporto d'aspetto */
    margin-left: 30px; /* Spazio tra testo e immagine */
}

/* Contenitore principale della pagina di manutenzione */
.maintenance-container {
    margin-top: 100px;
    margin-bottom: 120px;
    display: flex;
    justify-content: center; /* Centra orizzontalmente il contenuto */
    align-items: center; /* Allinea verticalmente al centro */
    background-size: cover;
    background-position: center;
    text-align: left; /* Il testo sarà allineato a sinistra */
}

/* Contenitore del testo e dell'immagine */
.maintenance-content {
    display: flex; /* Disposizione orizzontale del contenuto */
    align-items: center; /* Allinea il testo e l'immagine verticalmente al centro */
    justify-content: center; /* Centra orizzontalmente il contenuto */
    background-color: rgba(255, 255, 255, 0.9); /* Sfondo bianco semi-trasparente */
    padding: 40px 50px; /* Aumenta il padding per ingrandire il box */
    border-radius: 12px; /* Bordo leggermente più arrotondato */
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3); /* Maggiore ombra per dare più profondità */
    max-width: 1000px; /* Aumenta la larghezza massima del box */
    width: 100%; /* Assicura che il box occupi sempre il 100% della larghezza disponibile */
    transition: width 0.3s ease; /* Transizione per l'effetto di resizing */
}

/* Stile per il testo */
.maintenance-text {
    max-width: 600px; /* Limita la larghezza del testo */
    text-align: center; /* Centra il testo all'interno del box */
    margin-bottom: 20px; /* Aggiunge spazio sotto il testo */
}

/* Titolo e paragrafo */
.titolo {
    font-size: 26px;
    color: #ff4700; /* Colore del titolo */
    margin: 10px 0;
}

.testo {
    font-size: 18px !important;
    text-align: center !important; /* Centra il testo */
    width: 400px; /* Aumenta la larghezza del testo */
    margin-left: 0; /* Rimuove il margine sinistro */
    color: #333; /* Colore del testo */
}

/* Media Queries per ridimensionare il box */
@media (max-width: 1200px) {

}

@media (max-width: 900px) {
    .maintenance-content {
        padding: 20px 30px; /* Ulteriore riduzione del padding */
        max-width: 800px; /* Limita la larghezza massima */
        flex-direction: column; /* Cambia la direzione in colonna per schermi più piccoli */
        align-items: center; /* Centra gli elementi */
    }

    .maintenance-image {
        width: 220px; /* Larghezza costante per l'immagine */
        height: auto; /* Mantiene il rapporto d'aspetto */
        margin-left: 0; /* Rimuove il margine sinistro */
        margin-top: 0px; /* Spazio sopra l'immagine */
    }

    .testo {
        font-size: 14px !important;
        width: 100%; /* Assicura che il testo occupi il 100% della larghezza disponibile */
        margin: 4px;
        align-items: center;
    }

    .titolo {
        font-size: 25px;
    }
}

@media (max-width: 600px) {
    .maintenance-content {
        padding: 10px 20px; /* Ulteriore riduzione del padding */
        max-width: 500px; /* Limita la larghezza massima */
    }

    .maintenance-image {
        width: 120px; /* Larghezza costante per l'immagine */
        height: auto; /* Mantiene il rapporto d'aspetto */
        margin-left: 0; /* Rimuove il margine sinistro */
        margin-top: 10px; /* Spazio sopra l'immagine */
    }

    .testo {
        margin: 4px;
        align-items: center;
        max-width: 300px;
    }

    .titolo {
        font-size: 20px;
    }
}

@media (max-width: 400px) {
    .maintenance-content {
        padding: 10px; /* Padding minimo */
        max-width: 300px; /* Limita la larghezza massima per dispositivi mobili */
        width: 90%; /* Mantiene il box centrato */
    }

    .titolo {
        font-size: 1.8rem; /* Riduce la dimensione del font */
    }

    .testo {
        font-size: 14px !important; /* Riduce la dimensione del font per il testo */
        width: 100%; /* Assicura che il testo occupi il 100% della larghezza disponibile */
    }
}
