.page-container {
    text-align: left;
    max-width: 1000px; /* Larghezza massima del contenitore, puoi cambiarla come preferisci */
    margin: 0 auto; /* Centra orizzontalmente il contenuto */
    padding: 0 20px; /* Spazio interno ai lati del contenitore */
    box-sizing: border-box; /* Garantisce che il padding non aumenti la larghezza totale */
}

.info {
    margin-top: 20px ;
    text-align: left ;
    max-width: 800px ;
    margin-left: auto ;
    margin-right: auto ;
}

h3 {
    font-size: 22px;
    color: #333;
}

h4 {
    font-size: 16px;
    color: #333;
}

p {
    font-size: 14px !important;
    color: #555;
}

li {
    font-size: 14px;
    color: #555;
}

footer {
    margin-top: 30px;
    font-size: 12px;
    color: #777;
    text-align: center;
}