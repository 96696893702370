.footer {
    background-color: #ff4700;
    padding: 20px 20px;
    color: white;
    display: flex;
    flex-direction: column; /* Orientamento verticale */
    align-items: center; /* Centra gli elementi orizzontalmente */
}

.footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 10px;
    flex-wrap: wrap; /* Permette il wrapping degli elementi in base alla larghezza */
}

.footer-links {
    list-style: none;
    display: flex;
    gap: 20px;
}

.footer-links li {
    display: inline;
}

.footer-links a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #d6d6d6;
    text-decoration: none;
}

.footer-bottom {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px !important;
}

.footer-text {
    color: #ffffff;
}

.mailto {
    color: #ffffff;
}

.item_link-terms {
    color: #ffffff;
}

/* Media Query per i dispositivi mobili */
@media (max-width: 600px) {
    .footer-content {
        flex-direction: column; /* Imposta il contenuto in colonna sui dispositivi mobili */
        align-items: center; /* Centra gli elementi */
    }

    .footer-links {
        flex-direction: column; /* Disposizione verticale dei link */
        align-items: center; /* Centra i link */
        gap: 10px; /* Spazio tra i link */
    }

    .footer-links a {
        font-size: 16px; /* Riduce la dimensione del font per i link */
    }

    .footer-text {
        color: #ffffff;
        font-size: 10px !important;
    }
}

