/* Contenitore principale */
.contact-container {
    text-align: center;
}

/* Wrapper per le sezioni social */
.social-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Spazio ridotto tra i loghi */
    margin-top: 20px;
    flex-wrap: nowrap; /* Mantiene le icone orizzontali anche in versione mobile */
}

/* Sezione social individuale */
.social-discord, .social-telegram, .social-youtube, .social-twitch {
    text-align: center;
}

/* Icona social */
.social-icon {
    height: 70px;
    width: 70px;
    transition: transform 0.3s ease;
}

/* Hover effetto su icone social */
.social-icon:hover {
    transform: scale(1.1);
}

/* Titoli */
.titolo {
    font-size: 26px !important;
    color: #000000 !important;
    margin: 10px 0 !important;
}

.sottotitolo {
    font-size: 22px !important;
    color: #000000 !important;
    margin: 10px 0 !important;
}

/* Media Query per dispositivi mobili */
@media (max-width: 600px) {
    .social-wrapper {
        gap: 10px; /* Riduce ulteriormente lo spazio tra le icone sui dispositivi mobili */
    }

    .social-icon {
        height: 50px; /* Riduce ulteriormente la dimensione dell'icona per dispositivi mobili */
        width: 50px;
    }

    .titolo {
        font-size: 18px !important;
    }

    .sottotitolo {
        font-size: 16px !important;
    }
}
