.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-open {
    overflow-y: hidden;
}

.modal {
    background-color: var(--bg--color);
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
}

.modal-close {
    background: transparent;
    border: none;
    font-size: 20px;
    position: absolute;
    color: var(--grigio-chiaro);
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.modal-content {
    margin-top: 20px;
}

.modal-content h3 {
    color: white !important;
}

.modal-content h4 {
    color: white;
}

.modal-content p {
    color: white;
}

.modal-content li {
    color: white;
}