/* Contatti.css */
.contact-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

p {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 40px;
}

.social-section {
    margin-bottom: 40px;
}

.social-icon {
    width: auto;
    height: 50px;
    margin: 0 10px;
}

.community-section {
    margin-top: 40px;
}

.community-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.member-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    text-align: center;
    width: 200px; /* Larghezza fissa per il card */
}

.member-avatar {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}

.social-links a {
    margin: 5px;
    color: #007bff;
    text-decoration: none;
}

.social-links a:hover {
    text-decoration: underline;
}

@media (max-width: 600px) {
    .member-card {
        width: 150px;
    }

    .member-avatar {
        width: 80px;
        height: 80px;
    }
}