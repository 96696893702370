.navbar {
  background-color: #ff4700;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  flex-wrap: wrap;
  position: relative;
}

.navbar-logo {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
}

.navbar-logo img {
  height: 50px;
  margin-right: 10px;
}

.navbar-title {
  font-size: 24px;
  margin: 0;
  margin-top: -2px;
  margin-left: 10px;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 20px;
  transition: all 0.3s ease;
}

.navbar-links li {
  display: inline;
  margin: 0 !important;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #d6d6d6;
  text-decoration: none;
}

.menu-icon {
  font-size: 30px;
  display: none;
  cursor: pointer;
  transition: transform 0.4s ease;
}

.hamburger-icon, .close-icon {
  font-size: 30px;
  color: white;
  transition: transform 0.4s ease;
}

.menu-icon.open .hamburger-icon {
  transform: rotate(90deg) !important;
}

.buttons {
  font-size: 16px !important;
  text-align: right !important;
}

.navbar-title {
  font-size: 20px;
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
  }

  .navbar-links {
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-top: 0 !important;
  }

  .navbar-links li {
    text-align: right !important;
  }
  
  /* Elimina padding tra contenitori e bordo */
  .navbar-links {
    padding: 0 0;
  }

  /* Gestisci padding per il dropdown */
  .dropdown-container {
    padding-bottom: 0px !important; /* Mantiene sempre 10px in basso */
  }

  /* Mostra il dropdown quando è aperto */
  .dropdown-container.open {
    max-height: 300px;
    overflow: hidden;
    padding-top: 0px !important; /* Padding-top diventa 10px quando il menu è aperto */
    padding-bottom: 0px; /* Padding-bottom sempre a 10px quando aperto */
  }

  /* Dropdown container styles */
  .dropdown-container {
    position: absolute;
    top: 55px;
    right: 0;
    background-color: #ff4700;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out, padding-top 0.4s ease-in-out; /* Aggiungi transizione per il padding-top */
    padding-right: 20px !important;
    padding-top: 0 !important; /* Padding-top a 0 quando il menu è chiuso */
    margin-top: 10px !important;
  }
}
