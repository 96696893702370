.contact-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* Modifica la dimensione del paragrafo per i dispositivi mobili */
p {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 40px;
}

.community-section {
    margin-top: 40px;
}

.community-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.member-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    text-align: center;
    width: 200px; /* Larghezza fissa per il card */
}

/* Modifica le dimensioni degli avatar e delle card sui dispositivi mobili */
.member-avatar {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}

.titolo {
    font-size: 26px !important;
    color: #000000 !important; /* Colore del titolo */
    margin: 10px 0 !important;
}

.sottotitolo {
    font-size: 22px !important;
    color: #000000 !important; /* Colore del titolo */
    margin: 10px 0 !important;
}

/* Media Query per i dispositivi mobili */
@media (max-width: 600px) {
    .member-card {
        width: 150px; /* Larghezza delle card sui dispositivi mobili */
        padding: 10px; /* Riduce il padding per card su mobile */
    }

    .member-avatar {
        width: 80px; /* Riduce le dimensioni dell'avatar */
        height: 80px;
    }

    p {
        font-size: 1rem; /* Riduce la dimensione del font per il testo */
    }

    .titolo {
        font-size: 18px !important;
        color: #000000 !important; /* Colore del titolo */
        margin: 10px 0 !important;
    }

    .sottotitolo {
        font-size: 12px !important;
        color: #000000 !important; /* Colore del titolo */
        margin: 10px 0 !important;
    }
}
